import { Product } from "src/redux/apiTypes";
import { PriceProps } from "../../Price/Price";
import { WholesalePricesProps } from "../../WholesalePrices/WholesalePrices";

interface ProductTilePrices {
  wholesale?: Omit<WholesalePricesProps, 'language'>;
  old?: PriceProps;
  main?: PriceProps;
}

interface ProductTilePricesParams {
  product: Product;
  isAvailable: boolean;
}

export const useProductTilePrices = (params: ProductTilePricesParams): ProductTilePrices => {
  const { product: { discount, price_wholesale, currency, price, unit, bundle, is_uber_item }, isAvailable } = params;

  const hasWholesale = price_wholesale && price_wholesale.length > 0;
  const discountIsActive = !!discount?.status;

  if(is_uber_item) {
    return {};
  }

  const prices: ProductTilePrices = {
    main: {
      price,
      currency,
      isDiscount: discountIsActive,
      isUnavailable: !isAvailable,
    },
  };

  const wholesalePricesIsVisible = isAvailable && hasWholesale && !discountIsActive;

  if (wholesalePricesIsVisible) {
    prices.wholesale = {
      wholesalePrices: price_wholesale,
      currency,
      measure: unit,
      bundle,
    };
  }

  if (discountIsActive) {
    prices.old = {
      price: discount.old_price,
      currency,
    };
  }

  return prices;
};
